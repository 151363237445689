<template>
<div class="privacy">
  <Header/>
  <div class="privacy-con" v-if="!isEn">
    <h1 class="title">Little Star 隐私政策</h1>

    <p>LittleStar运营https://www.littlestarcloud.com网站，该网站提供服务。</p>

    <p>如果任何人决定使用我们的服务，本页面用于通知网站访问者关于我们收集、使用和披露个人信息的政策，Little Star网站。</p>

    <p>如果您选择使用我们的服务，即表示您同意收集和使用与本政策有关的信息。我们收集的个人信息将用于提供和改善本服务。除本隐私政策所述外，我们不会与任何人使用或共享您的信息。</p>

    <p>除非本隐私政策中另有定义，本隐私政策中使用的术语与我们的条款和条件具有相同的含义。我们的条款和条件可在https://www.littlestarcloud.com上查阅。</p>

    <h2>信息收集及使用</h2>

    <p>为了在使用我们的服务时获得更好的体验，我们可能会要求您向我们提供某些个人身份信息，包括但不限于您的邮件地址、GitHub相关信息。我们收集的信息将用于联系您或确认您的身份。</p>

    <h2>日志数据</h2>

    <p>我们想通知您，无论何时您访问我们的服务，我们都会收集您的浏览器发送给我们的信息，即所谓的日志数据。此记录数据可能包括如下信息，如您的计算机的互联网协议(“IP”)地址、浏览器版本、您访问我们服务的网页、您访问的时间和日期、在这些网页上花费的时间，以及其他统计数据。</p>

    <h2>Github数据</h2>

    <p>我们将只使用来自Github的信息进行界面展示和Stars管理。它不会被用于其他任何事情。我们不向第三方营销人员出售收集的个人身份信息。</p>
    <h2>Github access token</h2>
    <p>我们使用GitHub访问令牌来访问GitHub API。 </p>
    <p>LittleStar使用两个GitHub访问令牌:</p>
    <ul>
      <li>Token One: 用户在登录面板点击“GitHub登录”时，GitHub OAuth生成的令牌。此token仅使用 user:read scope，帮助用户快速生成帐户，Little Star 也使用它支持有限和基础功能。</li>
      <li>Token Second: 这个token由用户手动生成的，指定token使用的scope，可以为用户提供更多的权限，可以使用更多的Little Star的相关特性例如Star按钮功能。我们在本地存储这个token，且只有Litte Star才能访问。我们不会把它上传到任何地方，只用于访问Github API。你可以在设置面板中删除它。确保您只在可信的计算机上输入它们。</li>
    </ul>
    <p><a class="link" href="https://github.com/alianrock/LittleStar/wiki/Access-token-for-Little-Star">See more token information at wiki.</a></p>
    <h2>Cookies</h2>

    <p>cookie是包含少量数据的文件，通常作为匿名唯一标识符使用。这些信息会从你访问的网站发送到你的浏览器，并存储在你的电脑硬盘上。</p>

    <p>我们的网站使用这些“cookies”收集信息和改善我们的服务。您可以选择接受或拒绝这些cookie，并知道什么时候一个cookie被发送到您的计算机。如果您选择拒绝我们的cookie，您可能无法使用我们的部分服务。</p>

    <h2>Service Providers</h2>

    <p>我们可能会使用第三方服务，原因如下:</p>

    <ul>
        <li>方便我们的服务;</li>
        <li>代表我们提供服务;</li>
        <li>提供与服务有关的服务; </li>
        <li>协助我们分析如何使用我们的服务。</li>
    </ul>

    <p>我们希望告知我们的服务用户，这些第三方有权访问您的个人信息。原因是要以我们的名义完成分配给他们的任务。然而，他们有义务不披露或使用信息为任何其他目的。</p>

    <h2>安全</h2>

    <p>我们重视您对我们提供您的个人信息的信任，因此我们努力使用商业上可接受的方式来保护它。但请记住，任何通过互联网传输或电子存储的方法都不是100%安全可靠的，我们不能保证其绝对安全。</p>

    <h2>连结至其他网站</h2>

    <p>我们的服务可能包含到其他网站的链接。如果您点击第三方链接，您将被导向到该网站。请注意，这些外部站点不是由我们操作的。因此，我们强烈建议您查阅这些网站的隐私政策。我们对任何第三方网站或服务的内容、隐私政策或做法没有控制权，也不承担任何责任。</p>

    <h2>本隐私政策的变更</h2>

    <p>我们可能会不时更新我们的隐私政策。因此，我们建议您定期查看本页面的任何更改。我们将通过在本页发布新的隐私政策来通知您任何更改。这些更改在发布到本页后立即生效。</p>

    <h2>联系我们</h2>

    <p>如果您对我们的隐私政策有任何问题或建议，请与我们联系。</p>
  </div>
  <div class="privacy-con" v-else>
    <h1 class="title">Privacy Policy of LittleStar</h1>

    <p>LittleStar operates the https://www.littlestarcloud.com website, which provides the SERVICE.</p>

    <p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the littlestar website.</p>

    <p>If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. </p>

    <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at https://www.littlestarcloud.com, unless otherwise defined in this Privacy Policy.</p>

    <h2>Information Collection and Use</h2>

    <p>For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your email, Github data. The information that we collect will be used to contact or identify you.</p>

    <h2>Log Data</h2>

    <p>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol ("IP") address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>

    <h2>Github Data</h2>

    <p>We will only use information from Github for interface presentation and stars management. It will not be used for anything else. We do not sell personally identifiable information collected to third party marketers.</p>
    <h2>Github access token</h2>
    <p>We use GitHub access token to access GitHub API. </p>
    <p>LittleStar use two GitHub access tokens:</p>
    <ul>
      <li>Token One: Token generate by GitHub OAuth when user click "Github Login" at the login panel. This token only use <strong>user:read</strong> scope which help user generate account quickly and Little Star also use it to support limited and base features.</li>
      <li>Token Second: Token generate by user manually use <strong>repo</strong> scope which can offer user more permission and can use more Little Star feature like.We store this token in locally. It can only be accessed by LittleStar. We would not upload it to anywhere and only use for access Github API. You can remove it in Setting Panel. Make sure you only input them on trusted computers.</li>
    </ul>
    <p><a class="link" href="https://github.com/alianrock/LittleStar/wiki/Access-token-for-Little-Star">See more token information at wiki.</a></p>
    <h2>Github Data</h2>
    <p>We will only use information from Github for interface presentation and stars management. It will not be used for anything else. We do not sell personally identifiable information collected to third party marketers.</p>

    <h2>Cookies</h2>

    <p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive.</p>

    <p>Our website uses these "cookies" to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</p>

    <h2>Service Providers</h2>

    <p>We may employ third-party service due to the following reasons:</p>

    <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
    </ul>

    <p>We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>

    <h2>Security</h2>

    <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

    <h2>Links to Other Sites</h2>

    <p>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

    <h2>Changes to This Privacy Policy</h2>

    <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>

    <h2>Contact Us</h2>

    <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
  </div>
  <Footer/>
</div>
</template>
<script>
// @ is an alias to /src
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isEn: true,
    }
  },
  watch: {
    lang() {
      this.isEn = this.lang.indexOf('en') !== -1
    },
  },
  mounted() {
    this.isEn = this.lang.indexOf('en') !== -1
  },
}
</script>

<style lang="scss" scoped>
.privacy-con{
  padding: 36px;
  color: #484852;
  max-width: 1000px;
  margin: 0 auto 0;
  font-size: 16px;
  .title{
    font-size: 48px;
    color: #484852;
    line-height: 48px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 48px;
  }
  h2{
    font-size: 32px;
    color: #484852;
    line-height: 48px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  p{
    color: #646574;
    margin-bottom: 32px;
  }
  .link{
    color: #3582fb;
  }
  li{
    color: #646574;
    line-height: 18px;
    margin-left: 24px;
    list-style: disc;
  }
  ul{
    margin-bottom: 24px;
  }
}
</style>
